import { Form } from '@netivo/base-scripts';
import Glide from '@glidejs/glide';
// let lightbox = new GLightbox();

function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

// window.initMap = initMap;

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}

// let header = document.querySelector('.js-header');
// let sticky = header.offsetTop;
//
// window.onscroll = function(){stickyStart()};
//
// function stickyStart() {
//     if(window.pageYOffset > sticky){
//         header.classList.add('header--scrolled');
//     } else {
//         header.classList.remove('header--scrolled');
//     }
// }

window.addEventListener('DOMContentLoaded', () => {

    let modal = document.querySelector('.js-modal');
    if (modal !== null) {
        document.querySelector('body').classList.add('overlay');
        document.querySelector('body').style.overflowY = 'hidden';
        document.querySelector('html').style.overflowY = 'hidden';
        let close = modal.querySelector('[data-element="close"]');
        close.addEventListener('click', e => {
           e.preventDefault();

           modal.classList.remove('modal--shown');
            document.querySelector('body').classList.remove('overlay');
            document.querySelector('body').style.overflowY = 'initial';
            document.querySelector('html').style.overflowY = 'initial';
        });
    }

    let s_crls = null;
    let slider_carousel = document.querySelector('.js-slider');
    if (slider_carousel !== null) {
        s_crls = new Glide(slider_carousel, {
            perView: 1,
            gap: '0',
            type: 'slider',
            bound: true,
            autoplay: 6000,
        }).mount();
    }

    let t_crls = null;
    let trainings_carousel = document.querySelector('.js-courses-carousel');
    if (trainings_carousel !== null) {
        let prev_button = trainings_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = trainings_carousel.parentNode.querySelector('[data-element="button-next"]');

        let slides_container = trainings_carousel.querySelector('.glide__slides');
        let slides = trainings_carousel.querySelectorAll('.glide__slide');
        let slides_count = 3;
        let width = document.body.clientWidth;
        if (width <= 991) slides_count = 2;
        if (width <= 600) slides_count = 1;
        if (slides.length > slides_count) {
            t_crls = new Glide(trainings_carousel, {
                perView: 3,
                gap: '5',
                type: 'carousel',
                bound: true,
                breakpoints: {
                    650: {
                        perView: 1,
                    },
                    1250: {
                        perView: 2,
                    },
                }
            }).mount();

            prev_button.addEventListener('click', (e) => {
                e.preventDefault();

                t_crls.go('<');
            });
            next_button.addEventListener('click', (e) => {
                e.preventDefault();

                t_crls.go('>');
            });
        } else {
            prev_button.style.display = 'none';
            next_button.style.display = 'none';
            slides_container.classList.add('static');
        }
    }

    let rd_crls = null;
    let references_double_carousel = document.querySelector('.js-references-double-carousel');
    if (references_double_carousel !== null) {
        let prev_button = references_double_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = references_double_carousel.parentNode.querySelector('[data-element="button-next"]');

        let slides_container = references_double_carousel.querySelector('.glide__slides');
        let slides = references_double_carousel.querySelectorAll('.glide__slide');
        let slides_count = 2;
        let width = document.body.clientWidth;
        if (width <= 600) slides_count = 1;
        if (slides.length > slides_count) {
            rd_crls = new Glide(references_double_carousel, {
                perView: 2,
                gap: '5',
                type: 'carousel',
                bound: true,
                breakpoints: {
                    600: {
                        perView: 1,
                    },
                }
            }).mount();

            prev_button.addEventListener('click', (e) => {
                e.preventDefault();

                rd_crls.go('<');
            });
            next_button.addEventListener('click', (e) => {
                e.preventDefault();

                rd_crls.go('>');
            });
        } else {
            prev_button.style.display = 'none';
            next_button.style.display = 'none';
            slides_container.classList.add('static');
        }
    }

    let c_crls = null;
    let companies_carousel = document.querySelector('.js-companies-carousel');
    if (companies_carousel !== null) {
        let prev_button = companies_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = companies_carousel.parentNode.querySelector('[data-element="button-next"]');

        c_crls = new Glide(companies_carousel, {
            perView: 5,
            gap: '0',
            type: 'carousel',
            bound: true,
            autoplay: 6000,
            breakpoints: {
                480: {
                    perView: 1,
                },
                650: {
                    perView: 2,
                },
                900: {
                    perView: 3,
                },
                1200: {
                    perView: 4,
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            c_crls.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            c_crls.go('>');
        });
    }

    let contact_form = document.querySelector('.js-contact-form');
    if (contact_form !== null) {
        // let recaptcha_key = application_form.getAttribute('data-recaptcha');
        console.log(contact_form);
        let recaptcha_key = '6Lc2X2ccAAAAAK5btkNqyJB0J61hr6OiajCw5KLw';
        new Form(contact_form, {
            action: '/wp-json/bowarto/v1/contact_form',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        first_last_name: '',
                        contact_to: '',
                        email: '',
                        phone: '',
                        message: '',
                        // nonce: '',
                        recaptcha: '',
                    };

                    let form_first_last_name = el.querySelector('[name="first_last_name"]'),
                        form_contact_to = el.querySelector('[name="contact_to"]'),
                        form_email = el.querySelector('[name="email"]'),
                        form_phone = el.querySelector('[name="phone"]'),
                        form_message = el.querySelector('[name="message"]');

                    if (form_first_last_name !== null) data.first_last_name = form_first_last_name.value;
                    if (form_contact_to !== null) data.contact_to = form_contact_to.value;
                    if (form_email !== null) data.email = form_email.value;
                    if (form_phone !== null) data.phone = form_phone.value;
                    if (form_message !== null) data.message = form_message.value;
                    if (recaptcha_key !== null) {
                        grecaptcha.ready(function() {
                            grecaptcha.execute(recaptcha_key, {action: 'contact_form'}).then(function (token) {
                                data.recaptcha = token;
                                resolve(data);
                            });
                        });
                    }
                });
            },
            success: (el, response) => {
                if (response.status === 'success') {
                    let status = document.querySelector('[data-element="status"]');
                    let message = status.querySelector('[data-element="success-message"]');
                    let form_bcg = document.querySelector('.form__background');
                    status.style.display = 'flex';
                    status.querySelector('[data-element="error-message"]').style.display = 'none';
                    status.classList.add('shown');
                    form_bcg.classList.add('shown');
                    setTimeout( () => {
                        message.classList.add('shown');
                        el.querySelector('[name="first_last_name"]').value = '';
                        el.querySelector('[name="contact_to"]').value = '';
                        el.querySelector('[name="email"]').value = '';
                        el.querySelector('[name="phone"]').value = '';
                        el.querySelector('[name="message"]').value = '';
                    }, 500);
                }
            },
            error: (el, response) => {
                let status = document.querySelector('[data-element="status"]');
                let message = status.querySelector('[data-element="error-message"]');
                let form_bcg = document.querySelector('.form__background');
                status.style.display = 'flex';
                status.querySelector('[data-element="success-message"]').style.display = 'none';
                status.classList.add('shown');
                form_bcg.classList.add('shown');
                setTimeout( () => {
                    message.classList.add('shown');
                }, 500);
            }
        });
    }

    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        if (document.body.clientWidth <= 600) {
            window_top = document.body.scrollTop - 50;
        }
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height);

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);

    let application_button = document.querySelector('.js-course-application-button');
    if (application_button !== null) {
        application_button.addEventListener('click', (e) => {
            e.preventDefault();
           let modal = document.querySelector('.js-application-course-modal');
           if (modal !== null) {
               let body = document.querySelector('body');
               body.classList.add('overlay');
               modal.classList.add('modal--shown');

               document.querySelector('body').classList.add('overlay');
               document.querySelector('body').style.overflowY = 'hidden';
               document.querySelector('html').style.overflowY = 'hidden';
               let close = modal.querySelector('[data-element="close"]');
               close.addEventListener('click', e => {
                   e.preventDefault();

                   modal.classList.remove('modal--shown');
                   document.querySelector('body').classList.remove('overlay');
                   document.querySelector('body').style.overflowY = 'initial';
                   document.querySelector('html').style.overflowY = 'initial';
               });

               let form = modal.querySelector('.js-course-application-form');
               let recaptcha_key = '6Lc2X2ccAAAAAK5btkNqyJB0J61hr6OiajCw5KLw';
               if (form !== null) {
                   new Form(form, {
                       action: '/wp-json/bowarto/v1/application_course_form',
                       data: el => {
                           return new Promise((resolve, reject) => {
                               let data = {
                                   first_name: '',
                                   last_name: '',
                                   email: '',
                                   phone: '',
                                   course: '',
                                   question: '',
                                   recaptcha: ''
                               };

                               let form_first_name = el.querySelector('[name="first_name"]'),
                                   form_last_name = el.querySelector('[name="last_name"]'),
                                   form_email = el.querySelector('[name="email"]'),
                                   form_phone = el.querySelector('[name="phone"]'),
                                   form_question = el.querySelector('[name="question"]'),
                                   form_course = el.getAttribute('data-course-name');

                               if (form_first_name !== null) data.first_name = form_first_name.value;
                               if (form_last_name !== null) data.last_name = form_last_name.value;
                               if (form_email !== null) data.email = form_email.value;
                               if (form_phone !== null) data.phone = form_phone.value;
                               if (form_question !== null) data.question = form_question.value;
                               if (form_course !== null) data.course = form_course;
                               if (recaptcha_key !== null) {
                                   grecaptcha.ready(function() {
                                       grecaptcha.execute(recaptcha_key, {action: 'application_course_form'}).then(function (token) {
                                           data.recaptcha = token;
                                           resolve(data);
                                       });
                                   });
                               }
                           });
                       },
                       success: (el, response) => {
                           if (response.status === 'success') {
                               let status = document.querySelector('[data-element="status"]');
                               let message = status.querySelector('[data-element="success-message"]');
                               let form_bcg = document.querySelector('.form__background');
                               status.style.display = 'flex';
                               status.querySelector('[data-element="error-message"]').style.display = 'none';
                               status.classList.add('shown');
                               form_bcg.classList.add('shown');
                               setTimeout( () => {
                                   message.classList.add('shown');
                                   if (el.querySelector('[name="first_name"]') !== null) {
                                       el.querySelector('[name="first_name"]').value = '';
                                   }
                                   if (el.querySelector('[name="last_name"]') !== null) {
                                       el.querySelector('[name="last_name"]').value = '';
                                   }
                                   if (el.querySelector('[name="question"]') !== null) {
                                       el.querySelector('[name="question"]').value = '';
                                   }
                                   if (el.querySelector('[name="email"]') !== null) {
                                       el.querySelector('[name="email"]').value = '';
                                   }
                                   if (el.querySelector('[name="phone"]') !== null) {
                                       el.querySelector('[name="phone"]').value = '';
                                   }
                               }, 500);
                           }
                       },
                       error: (el, response) => {
                           let status = document.querySelector('[data-element="status"]');
                           let message = status.querySelector('[data-element="error-message"]');
                           let form_bcg = document.querySelector('.form__background');
                           status.style.display = 'flex';
                           status.querySelector('[data-element="success-message"]').style.display = 'none';
                           status.classList.add('shown');
                           form_bcg.classList.add('shown');
                           setTimeout( () => {
                               message.classList.add('shown');
                           }, 500);
                       }
                   });
               }
           }
        });
    }
});

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 100;
    if (document.body.clientWidth <= 600) {
        window_top = document.body.scrollTop - 50;
    }
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 200;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});

